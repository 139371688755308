import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isBrowser } from '../utils/functions';

const useGetWindowDimensions = () => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const firstRender = useRef(true);

  const onResize = useCallback(() => {
    if (isBrowser()) {
      const { innerHeight, innerWidth } = window;
      setHeight(innerHeight);
      setWidth(innerWidth - 48);
    }
  }, []);

  useEffect(() => {
    if (firstRender) {
      onResize();
      firstRender.current = false;
    }
  }, [onResize]);

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', onResize);
    }
    return () =>
      window.removeEventListener('resize', onResize);
  }, [onResize]);

  return { width, height };
};

export default useGetWindowDimensions;

import React from 'react';
import OrgImg from 'gatsby-image/withIEPolyfill';
import {
  oneOf,
  number,
  string,
  oneOfType,
  bool,
  arrayOf,
} from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { variants } from '../../constants/patterns';
import { Parallax } from 'react-scroll-parallax';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(
  ({ patterns, breakpoints: { down } }) => ({
    imageContainer: {
      position: 'relative',
      width: ({ width }) => width,
      height: ({ height }) => height,
      '& .parallax-inner': {
        width: ({ width }) => width,
        height: ({ height }) => height,
        position: 'absolute',
        top: ({ top }) => top && 0,
        right: ({ right }) => right && 0,
        bottom: ({ bottom }) => bottom && 0,
      },
    },
    image: {
      width: ({ width }) => width,
      height: ({ height }) => height,
      overflow: 'visible !important',
      zIndex: 3,
    },
    pattern: {
      content: '""',
      width: ({ width }) => width,
      height: ({ height }) => height,
      background: ({ pattern }) =>
        `url(${patterns[pattern]})`,
      opacity: 0.5,
      position: 'absolute',
      top: ({ top }) => `${top}px`,
      right: ({ right }) => `${right}px`,
      bottom: ({ bottom }) => `${bottom}px`,
      zIndex: -1,
      [down(325)]: {
        backgroundPosition: 'center !important',
      },
    },
  })
);

const Image = ({
  pattern,
  width,
  height,
  className,
  top,
  right,
  left,
  bottom,
  parallax,
  parallaxProps,
  ...props
}) => {
  const classes = useStyles({
    pattern,
    width,
    height,
    top,
    right,
    left,
    bottom,
  });
  return (
    <Box className={classes.imageContainer}>
      <OrgImg
        {...props}
        className={`${classes.image} ${
          className ? className : ''
        }`}
        draggable={false}
      />
      <Parallax disabled={!parallax} y={parallaxProps}>
        <Box className={classes.pattern} />
      </Parallax>
    </Box>
  );
};

Image.propTypes = {
  pattern: oneOf(variants),
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  top: number,
  left: number,
  right: number,
  bottom: number,
  className: string,
  parallax: bool,
  parallaxProps: arrayOf(number),
};

Image.defaultProps = {
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};

export default Image;

import {
  Box,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { string, number } from 'prop-types';

const useStyles = makeStyles(({ spacing }) => ({
  titleContainer: {
    position: 'relative',
  },
  titleSmall: {
    width: '50%',
    height: '50%',
    overflow: 'hidden',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  stepCounter: {
    fontStyle: 'italic',
    opacity: 0.2,
    fontWeight: 'bold',
    lineHeight: '70px',
  },
  stepText: {
    paddingTop: spacing(4),
  },
}));

const Step = ({
  stepNumber,
  stepTitle,
  stepDescription,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );

  return (
    <>
      <Box className={classes.titleContainer}>
        <Typography
          variant="h1"
          className={classes.stepCounter}
        >
          {stepNumber}
        </Typography>
        <Typography
          variant="h6"
          className={classes.titleSmall}
        >
          {stepTitle}
        </Typography>
      </Box>
      <Typography
        variant={smallScreen ? 'body2' : 'body1'}
        className={classes.stepText}
      >
        {stepDescription}
      </Typography>
    </>
  );
};

export default Step;

Step.propTypes = {
  stepNumber: number,
  stepTitle: string,
  stepDescription: string,
};

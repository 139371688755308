import { useEffect, useState } from 'react';
import InApp from 'detect-inapp';
import { isBrowser } from '../utils/functions';

const useGetUserAgent = () => {
  const [isInApp, setIsInApp] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (isBrowser() && navigator) {
      const inApp = new InApp(
        navigator.userAgent ||
          navigator.vendor ||
          window.opera
      );
      setIsInApp(inApp.isInApp);
      setIsMobile(inApp.isMobile);
    }
  }, []);

  return { isInApp, isMobile };
};

export default useGetUserAgent;

import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { bool, number, shape } from 'prop-types';

const useStyles = makeStyles(
  ({ palette: { primary }, breakpoints: { down } }) => ({
    Circle: {
      width: ({ size, useCssToPosition }) =>
        useCssToPosition ? 266 : size,
      height: ({ size, useCssToPosition }) =>
        useCssToPosition ? 266 : size,
      top: ({ top, useCssToPosition }) =>
        useCssToPosition ? 75 : top.lg,
      right: ({ right, useCssToPosition }) =>
        useCssToPosition ? 220 : right.lg,
      backgroundColor: ({ outlined }) =>
        !outlined ? primary.main : 'transparent',
      border: ({ outlined }) =>
        outlined && `1px solid ${primary.main}`,
      borderRadius: '50%',
      position: 'absolute',
      zIndex: 2,
      [down('md')]: {
        top: ({ top, useCssToPosition }) =>
          useCssToPosition ? 0 : top.md,
        right: ({ right, useCssToPosition }) =>
          useCssToPosition ? 200 : right.md,
      },
      [down('sm')]: {
        top: ({ top, useCssToPosition }) =>
          useCssToPosition ? 0 : top.sm,
        right: ({ right, useCssToPosition }) =>
          useCssToPosition ? 200 : right.sm,
      },
      [down('xs')]: {
        top: ({ top, useCssToPosition }) =>
          useCssToPosition ? -60 : top.xs,
        right: ({ right, useCssToPosition }) =>
          useCssToPosition ? -60 : right.xs,
      },
      [down(325)]: {
        width: ({ useCssToPosition }) =>
          useCssToPosition && 200,
        height: ({ useCssToPosition }) =>
          useCssToPosition && 200,
      },
    },
  })
);

const Circle = ({
  size,
  top,
  right,
  outlined,
  useCssToPosition,
}) => {
  const classes = useStyles({
    size,
    top,
    right,
    outlined,
    useCssToPosition,
  });
  return <div className={classes.Circle} />;
};

Circle.propTypes = {
  size: number,
  top: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
  }),
  right: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
  }),
  outlined: bool,
  useCssToPosition: bool,
};

Circle.defaultProps = {
  useCssToPosition: false,
};

export default Circle;

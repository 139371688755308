import {
  Grid,
  Typography,
  useMediaQuery,
  ButtonBase,
} from '@material-ui/core';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { string } from 'prop-types';
import { Link, navigate } from 'gatsby-plugin-intl';
import Arrow from '../../../svg/Arrow';
import styles from './index.styles';
import Logo from '../../../svg/Logo';

const applyLink = '/apply';

const useStyles = makeStyles(styles);

const CallToActionSection = ({
  title,
  description,
  buttonTitle,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const smallScreen = useMediaQuery(
    theme.breakpoints.down('sm')
  );

  return (
    <Grid
      container
      direction="row"
      className={classes.callToActionSection}
    >
      {!smallScreen && (
        <Grid
          className={`${classes.basePattern} ${classes.leftPattern}`}
        />
      )}
      <Grid
        container
        direction="column"
        alignContent={smallScreen ? 'flex-start' : 'center'}
        alignItems="center"
        className={classes.innerContainer}
      >
        <Typography
          variant="h2"
          component="h1"
          className={classes.title}
          align={smallScreen ? 'left' : 'center'}
        >
          {title}
        </Typography>
        <Typography
          variant={smallScreen ? 'body2' : 'body1'}
          component="p"
          className={classes.description}
          align={smallScreen ? 'left' : 'center'}
        >
          {description}
        </Typography>
        <ButtonBase
          className={classes.applyButton}
          onClick={() => navigate(applyLink)}
        >
          <Link
            to={applyLink}
            className={classes.applyButtonLink}
          >
            {buttonTitle}
            <Logo width="4.5em" />
          </Link>
          <Arrow />
        </ButtonBase>
      </Grid>
      <Grid
        className={`${classes.basePattern} ${classes.rightPattern}`}
      />
    </Grid>
  );
};

CallToActionSection.propTypes = {
  title: string,
  description: string,
  buttonTitle: string,
};

export default CallToActionSection;

import React from 'react';
import { bool, number } from 'prop-types';

const ArrowDivider = ({ isLong, width }) => {
  return (
    <>
      {isLong ? (
        <svg
          width={`${width}`}
          height="31"
          viewBox={`0 0 ${width} 31`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M${width} 11.5H1.00005L1 31`}
            stroke="currentColor"
          />
          <path
            d={`M${width - 10} 0.889492L${width} 11.4961L${
              width - 10
            } 22.1027`}
            stroke="currentColor"
          />
        </svg>
      ) : (
        <svg
          width={`${width / 2}`}
          height="31"
          viewBox={`0 0 ${width / 2} 31`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M${width / 2} 11.5001L1.00007 11.5L1 30.5`}
            stroke="currentColor"
          />
          <path
            d={`M${width / 2 - 12} 1.00009L${
              width / 2
            } 11.6067L ${width / 2 - 12} 22.2133`}
            stroke="currentColor"
          />
        </svg>
      )}
    </>
  );
};

ArrowDivider.propTypes = {
  isLong: bool,
  width: number,
};

export default ArrowDivider;

import {
  Grid,
  Box,
  Typography,
  Container,
  Divider,
  useMediaQuery,
} from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import Circles from '../../../svg/Circles';
import Step from './Step';
import { string, shape, arrayOf } from 'prop-types';
import styles from './index.styles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Parallax } from 'react-scroll-parallax';
import ArrowDivider from '../../../svg/ArrowDivider';
import DashedDivider from '../../../svg/DashedDivider';
import useGetWindowDimensions from '../../../../hooks/useGetWindowDimensions';
import useGetUserAgent from '../../../../hooks/useGetUserAgent';

const useStyles = makeStyles(styles);

const StepSection = ({ title, steps, extraSteps }) => {
  const { width } = useGetWindowDimensions();
  const theme = useTheme();
  const classes = useStyles({ extraSteps });
  const { isInApp } = useGetUserAgent();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down('sm')
  );
  const largeScreen = useMediaQuery(
    theme.breakpoints.down('md')
  );
  const extraLargeScreen = useMediaQuery(
    theme.breakpoints.up('lg')
  );

  useEffect(() => {
    AOS.init({
      disable: isInApp,
    });
  }, [isInApp]);

  return (
    <Grid
      container
      direction="column"
      className={classes.stepSections}
      justify="center"
      alignItems="center"
    >
      <Box className={classes.rowContainer}>
        <Typography
          className={classes.titleLarge}
          variant="h2"
        >
          {title}
        </Typography>
        <Box className={classes.circleContainer}>
          <Parallax y={smallScreen ? [0, 25] : [-25, 25]}>
            <Circles />
          </Parallax>
        </Box>
      </Box>
      <Container>
        <Grid
          container
          direction="row"
          className={classes.stepsContainer}
        >
          {steps.map((step, index) => {
            return (
              <Fragment key={step.title}>
                {index !== 0 && (
                  <Divider
                    orientation={
                      mediumScreen
                        ? 'horizontal'
                        : 'vertical'
                    }
                    flexItem
                    className={classes.divider}
                    variant={
                      mediumScreen ? 'middle' : undefined
                    }
                  />
                )}
                <Grid
                  item
                  md={Number(index + 1) === 2 ? 5 : true}
                  className={classes.stepContainer}
                  data-aos="fade"
                  data-aos-delay={index * 200}
                  data-aos-duration="1000"
                >
                  <Step
                    stepNumber={index + 1}
                    stepTitle={step.title}
                    stepDescription={step.description}
                  />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        {extraSteps && !mediumScreen && (
          <Grid
            container
            direction="column"
            className={classes.extraStepsContainer}
          >
            <Grid
              container
              item
              md={12}
              direction="column"
              data-aos="fade"
              data-aos-delay={0}
              data-aos-duration="1000"
            >
              <ArrowDivider
                isLong={true}
                width={
                  !largeScreen && extraLargeScreen
                    ? 1232
                    : width
                }
              />
              <Typography
                className={classes.extraStepsText}
              >
                {extraSteps[0]}
              </Typography>
            </Grid>
            <Grid
              container
              item
              md={6}
              direction="column"
              className={classes.middleDivider}
              data-aos="fade"
              data-aos-delay={200}
              data-aos-duration="1000"
            >
              <ArrowDivider
                width={
                  !largeScreen && extraLargeScreen
                    ? 1232
                    : width
                }
              />
              <Typography
                className={classes.extraStepsText}
              >
                {extraSteps[1]}
              </Typography>
            </Grid>
            <Grid
              container
              item
              md={12}
              direction="column"
              className={classes.dividerContainer}
              data-aos="fade"
              data-aos-delay={400}
              data-aos-duration="1000"
            >
              <DashedDivider
                width={
                  !largeScreen && extraLargeScreen
                    ? 1232
                    : width
                }
              />
              <Typography
                className={`${classes.extraStepsText} ${classes.centeredStep}`}
              >
                {extraSteps[2]}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Container>
    </Grid>
  );
};

StepSection.propTypes = {
  title: string,
  steps: arrayOf(
    shape({
      title: string,
      description: string,
    })
  ),
  extraSteps: arrayOf(string),
};

export default StepSection;

export default ({
  palette: { primary, text },
  spacing,
  fonts,
  breakpoints: { down },
}) => ({
  stepSections: {
    backgroundColor: primary.dark,
    color: text.secondary,
  },
  circleContainer: {
    color: primary.main,
    position: 'absolute',
    zIndex: 1,
    top: -70,
    right: -35,
    [down('sm')]: {
      right: 30,
      top: -30,
    },
    [down('xs')]: {
      right: 40,
      top: -60,
    },
  },
  titleLarge: {
    textAlign: 'center',
    position: 'relative',
    zIndex: 2,
    [down('sm')]: {
      whiteSpace: 'pre-wrap',
    },
    [down('xs')]: {
      fontSize: fonts.size.xxxxxm,
    },
    [down(325)]: {
      fontSize: fonts.size.xxxxm,
    },
  },
  rowContainer: {
    marginTop: spacing(23),
    position: 'relative',
    [down('sm')]: {
      padding: spacing(0, 4),
    },
    [down('xs')]: {
      padding: spacing(0),
    },
  },
  stepsContainer: {
    padding: ({ extraSteps }) =>
      extraSteps
        ? spacing(15, 0, 13, 0)
        : spacing(15, 0, 21, 0),
    [down('sm')]: {
      paddingBottom: `${spacing(5)}px!important`,
      justifyContent: 'center',
    },
  },
  divider: {
    backgroundColor: text.secondary,
    opacity: 0.1,
    [down('sm')]: {
      width: '100%',
      height: 1,
      flexShrink: 1,
      margin: spacing(0, 5),
    },
  },
  stepContainer: {
    position: 'relative',
    textAlign: 'center',
    padding: spacing(0, 5),
    '&:nth-child(2)': {
      padding: spacing(0, 6),
      [down('sm')]: {
        padding: spacing(0),
      },
    },
    [down('sm')]: {
      margin: spacing(0, 5),
      padding: spacing(8, 0, 8, 0),
    },
  },
  extraStepsContainer: {
    paddingBottom: spacing(19),
  },
  extraStepsText: {
    fontStyle: 'italic',
    fontWeight: 300,
    padding: spacing(0, 1),
  },
  centeredStep: {
    padding: spacing(1, 0, 0, 0),
    textAlign: 'center',
  },
  dividerContainer: {
    paddingTop: spacing(2.5),
  },
  middleDivider: {
    alignSelf: 'flex-end',
  },
});

export default ({
  palette: { primary, secondary, text },
  breakpoints: { down },
  spacing,
  fonts,
}) => ({
  PageSection: {
    backgroundColor: ({ isLight }) =>
      isLight ? text.secondary : secondary.dark,
    color: text.primary,
    padding: spacing(24, 0, 28, 0),
    [down('md')]: {
      padding: spacing(5, 0, 13, 0),
    },
    [down('xs')]: {
      paddingTop: spacing(11),
      position: 'relative',
      zIndex: 2,
    },
  },
  gridContainer: {
    zIndex: 2,
    position: 'relative',
    [down('md')]: {
      paddingTop: spacing(5),
      flexDirection: 'column-reverse',
    },
    [down('xs')]: {
      paddingTop: spacing(0),
      flexDirection: 'column',
    },
  },
  textContainer: {
    position: 'relative',
    height: '100%',
    zIndex: 3,
    paddingLeft: spacing(18),
    [down('md')]: {
      paddingLeft: spacing(0),
    },
    [down('xs')]: {
      height: 'auto',
    },
  },
  title: {
    whiteSpace: 'pre-line',
  },
  descriptionContainer: {
    whiteSpace: 'pre-line',
    marginTop: spacing(4),
    [down('md')]: {
      maxWidth: 700,
    },
    [down('xs')]: {
      marginTop: spacing(2.5),
    },
  },
  subTitleText: {
    fontWeight: 500,
    maxWidth: '90%',
  },
  imageContainer: {
    position: 'relative',
    marginTop: ({ isLight }) =>
      isLight ? spacing(0) : spacing(4),
    maxWidth: ({ width }) => width,
    [down('md')]: {
      alignSelf: 'flex-end',
      margin: `${spacing(8, 23, 3, 0)}!important`,
    },
    [down('sm')]: {
      alignSelf: 'flex-end',
      marginRight: `${spacing(15)}px!important`,
    },
    [down('xs')]: {
      maxWidth: '100%!important',
      overflow: ({ isLight }) => isLight && 'hidden',
      marginTop: `${spacing(0)}!important`,
      marginBottom: ({ isLight }) =>
        isLight
          ? `${spacing(5)}px!important`
          : `${spacing(15)}px!important`,
    },
  },
  circleContainer: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    '& .parallax-outer': {
      position: 'absolute',
      right: 0,
      height: 188,
      width: 188,
    },
    '& .parallax-inner': {
      position: 'absolute',
      right: 0,
      height: '100%',
      width: '100%',
    },
  },
  testimonyContainer: {
    backgroundColor: primary.main,
    color: text.primary,
    padding: spacing(4),
    textAlign: 'center',
    width: 350,
    position: 'absolute',
    right: -50,
    bottom: -60,
    zIndex: 4,
    [down('xs')]: {
      display: 'none',
    },
  },
  testimony: {
    fontSize: fonts.size.xs,
    lineHeight: `${fonts.size.xs + 8}px`,
    fontWeight: 700,
  },
  author: {
    paddingTop: spacing(2.5),
    fontSize: fonts.size.xxxs,
  },
});

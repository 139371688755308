import { useEffect, useState } from 'react';
import { isBrowser } from '../utils/functions';

const useGetWindowHeight = () => {
  const [height, setHeight] = useState(null);
  const { innerHeight } = isBrowser() && window;

  useEffect(() => {
    if (isBrowser()) {
      setHeight(innerHeight);
    } else {
      setHeight(null);
    }
  }, [innerHeight]);

  return height;
};

export default useGetWindowHeight;

import React from 'react';
import { number } from 'prop-types';

const DashedDivider = ({ width }) => {
  return (
    <>
      <svg
        width={`${width}`}
        height="22"
        viewBox={`0 0 ${width} 22`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`M${width} 0V21.5H1V0`}
          stroke="currentColor"
          strokeDasharray="10 10"
        />
      </svg>
    </>
  );
};

DashedDivider.propTypes = {
  width: number,
};

export default DashedDivider;

import patterns from '../../../../constants/patterns';

export default ({
  palette: { primary, text },
  fonts,
  spacing,
  breakpoints: { down },
}) => ({
  callToActionSection: {
    backgroundColor: primary.main,
    color: text.primary,
    padding: spacing(13, 0),
    position: 'relative',
    [down('xs')]: {
      padding: spacing(13, 4),
    },
  },
  innerContainer: {
    alignItems: 'center',
    [down('md')]: {
      padding: spacing(0, 24),
    },
    [down('sm')]: {
      alignItems: 'flex-start',
      padding: spacing(0, 7),
    },
    [down('xs')]: {
      padding: spacing(0),
    },
  },
  title: {
    maxWidth: 540,
    marginBottom: spacing(4),
    whiteSpace: 'pre-line',
    position: 'relative',
    zIndex: 3,
    [down('xs')]: {
      maxWidth: '100%',
      fontSize: fonts.size.xxxxm - 1,
    },
  },
  description: {
    position: 'relative',
    zIndex: 3,
    fontWeight: 500,
    maxWidth: 540,
    [down('xs')]: {
      maxWidth: '100%',
    },
  },
  applyButton: {
    backgroundColor: primary.dark,
    width: 285,
    height: 55,
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(0, 2.5),
    color: text.secondary,
    marginTop: spacing(8),
    zIndex: 2,
    transition: 'opacity .3s',
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:hover': {
      opacity: 0.9,
      '& svg:not(:first-child)': {
        animation: '$hoverEffect 1.5s infinite',
      },
    },
    [down('xs')]: {
      width: '100%',
    },
  },
  applyButtonLink: {
    color: text.secondary,
    textDecoration: 'none',
    fontSize: fonts.size.xs,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      margin: spacing(0.4, 0, 0, 0.7),
    },
  },
  basePattern: {
    width: '50%',
    height: '100%',
    display: 'block',
    position: 'absolute',
    top: 0,
    opacity: 0.2,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      background: `radial-gradient(43.58% 60.41% at 88.75% 18.11%, rgba(230, 252, 87, 0.04) 0%, ${primary.main} 100%)`,
    },
    [down('sm')]: {
      width: '40%',
    },
  },
  leftPattern: {
    background: `url(${patterns['arrow']})`,
    left: 0,
    '&::after': {
      left: 0,
      transform: 'rotate(-180deg)',
    },
  },
  rightPattern: {
    background: `url(${patterns['alternatedSquares']})`,
    right: 0,
    '&::after': {
      right: 0,
      [down('xs')]: {
        background: `radial-gradient(52.09% 48.8% at 81.73% 4.51%, rgba(230, 252, 87, 0.04) 0%, ${primary.main} 100%)`,
        bottom: 0,
        right: 0,
        transform: 'matrix(1, 0, 0, -1, 0, 0)',
      },
    },
    [down('xs')]: {
      width: '100%',
    },
  },
  '@keyframes hoverEffect': {
    '0%': {
      transform: 'translateX(0)',
    },
    '50%': {
      transform: 'translateX(7px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
});

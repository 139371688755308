import React from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  Box,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import Image from '../../Image';
import Container from '../../Container';
import {
  string,
  shape,
  number,
  oneOf,
  oneOfType,
  bool,
} from 'prop-types';
import Circle from '../../Circle';
import { variants } from '../../../../constants/patterns';
import ApplyLink from '../../ApplyLink';
import styles from './index.styles';
import { Parallax } from 'react-scroll-parallax';

const useStyles = makeStyles(styles);

const PageSection = ({
  title,
  description,
  buttonText,
  gatsbyImg,
  showCircles,
  isLight,
  testimony,
}) => {
  const { width } = gatsbyImg;
  const theme = useTheme();
  const classes = useStyles({ width, isLight });

  const extraSmallScreen = useMediaQuery(
    theme.breakpoints.down(325)
  );
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down('md')
  );

  return (
    <Grid
      container
      className={classes.PageSection}
      alignContent="center"
    >
      <Container>
        <Grid
          container
          className={classes.gridContainer}
          direction={mediumScreen ? 'column' : 'row'}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={classes.imageContainer}
          >
            {testimony && (
              <Grid
                container
                item
                className={classes.testimonyContainer}
                justify="center"
              >
                <Typography className={classes.testimony}>
                  {testimony.description}
                </Typography>
                <Typography className={classes.author}>
                  {testimony.author}, {testimony.company}
                </Typography>
              </Grid>
            )}
            {!smallScreen && showCircles && (
              <Box className={classes.circleContainer}>
                <Parallax
                  y={mediumScreen ? [-40, 70] : [-25, 100]}
                >
                  <Circle
                    size={188}
                    top={{
                      xs: -40,
                      sm: -90,
                      md: -90,
                      lg: -80,
                    }}
                    right={{
                      xs: -100,
                      sm: -70,
                      md: -70,
                      lg: -90,
                    }}
                  />
                  <Circle
                    outlined
                    size={78}
                    top={{
                      xs: -40,
                      sm: 40,
                      md: 40,
                      lg: 60,
                    }}
                    right={{
                      xs: -100,
                      sm: -60,
                      md: -60,
                      lg: -80,
                    }}
                  />
                </Parallax>
              </Box>
            )}
            <Image
              {...gatsbyImg}
              right={
                mediumScreen && !smallScreen
                  ? -50
                  : smallScreen && !extraSmallScreen
                  ? -55
                  : extraSmallScreen
                  ? -15
                  : -70
              }
              bottom={
                mediumScreen && !smallScreen
                  ? -40
                  : smallScreen && !extraSmallScreen
                  ? -55
                  : extraSmallScreen
                  ? -115
                  : -60
              }
              top={null}
              parallax={true}
              parallaxProps={
                mediumScreen && !smallScreen
                  ? [-10, 20]
                  : smallScreen
                  ? [-20, 20]
                  : [-20, 30]
              }
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={7}
            lg={6}
            direction={mediumScreen ? 'column' : 'row'}
            className={classes.textContainer}
          >
            <Typography
              variant={smallScreen ? 'h4' : 'h2'}
              className={classes.title}
            >
              {title}
            </Typography>
            <Grid
              container
              className={classes.descriptionContainer}
              direction="row"
            >
              <Typography
                variant={
                  smallScreen ? 'body2' : 'subtitle2'
                }
                component="p"
                className={classes.subTitleText}
              >
                {description}
              </Typography>
              {buttonText && (
                <ApplyLink title={buttonText} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

PageSection.propTypes = {
  title: string,
  description: string,
  buttonText: string,
  gatsbyImg: shape({
    fluid: shape({}),
    width: oneOfType[(number, string)],
    height: oneOfType[(number, string)],
    pattern: oneOf(variants),
  }),
  showCircles: bool,
  testimony: shape({
    description: string,
    author: string,
    company: string,
  }),
};

PageSection.defaultProps = {
  showCircles: true,
};

export default PageSection;

import React from 'react';
import { string, bool } from 'prop-types';
import Arrow from '../svg/Arrow';
import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Link } from 'gatsby-plugin-intl';
import Logo from '../svg/Logo';

const applyLink = '/apply';

const useStyles = makeStyles(
  ({
    palette: { primary, text },
    fonts,
    spacing,
    breakpoints: { down },
  }) => ({
    applyButton: {
      height: 55,
      display: 'flex',
      justifyContent: 'flex-start',
      color: text.primary,
      marginTop: spacing(8),
      zIndex: 2,
      '& .MuiTouchRipple-root': {
        color: 'transparent',
        zIndex: -1,
      },
      transition: 'opacity .3s',
      '&:hover': {
        opacity: 0.8,
        '& svg:not(:last-child)': {
          animation: '$hoverEffect 1.5s infinite',
        },
      },
      [down('xs')]: {
        width: '100%',
      },
    },
    applyButtonLink: {
      color: text.primary,
      textDecoration: 'none',
      fontSize: fonts.size.xm,
      fontWeight: 'bold',
      paddingRight: spacing(1.5),
      position: 'relative',
      '& svg:first-child': {
        verticalAlign: -5,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 3,
        left: 0,
        height: 7,
        width: `calc(100% - ${spacing(1.5)}px)`,
        background: primary.dark,
        opacity: 0.1,
        zIndex: -1,
      },
    },
    '@keyframes hoverEffect': {
      '0%': {
        transform: 'translateX(0)',
      },
      '50%': {
        transform: 'translateX(7px)',
      },
      '100%': {
        transform: 'translateX(0)',
      },
    },
  })
);

const ApplyLink = ({ title, tailed }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <ButtonBase className={classes.applyButton}>
      <Link
        to={applyLink}
        className={classes.applyButtonLink}
      >
        {title}
        <Logo width="4.3em" />
      </Link>
      <Arrow tailed={tailed} />
    </ButtonBase>
  );
};

ApplyLink.propTypes = {
  title: string,
  tailed: bool,
};

ApplyLink.defaultProps = {
  tailed: false,
};

export default ApplyLink;

import React from 'react';
import { bool } from 'prop-types';

const Circles = ({
  showLargeCircle,
  showSmallCircle,
  ...props
}) => {
  const showBothCircles =
    showLargeCircle && showSmallCircle;
  return (
    <>
      {showBothCircles && (
        <svg
          width="167"
          height="161"
          viewBox="0 0 167 161"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="79.5"
            cy="81.5"
            r="79"
            stroke="currentColor"
          />
          <circle
            cx="137.951"
            cy="28.9509"
            r="28.4509"
            stroke="currentColor"
          />
        </svg>
      )}
      {showLargeCircle && !showBothCircles && (
        <svg
          width="159"
          height="159"
          viewBox="0 0 159 159"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <circle
            cx="79.5"
            cy="79.5"
            r="79"
            stroke="currentColor"
          />
        </svg>
      )}
      {showSmallCircle && !showBothCircles && (
        <svg
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <circle
            cx="28.9509"
            cy="28.9509"
            r="28.4509"
            stroke="currentColor"
          />
        </svg>
      )}
    </>
  );
};

export default Circles;

Circles.propTypes = {
  showLargeCircle: bool,
  showSmallCircle: bool,
};

Circles.defaultProps = {
  showLargeCircle: true,
  showSmallCircle: true,
};
